/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as React from 'react';
import { InputError, InputLabel } from '../InputLabel';

interface FormInputProps {
  id: string,
  type: string,
  label?: string,
  className?: string,
  inputClassName?: string,
  disabled?: boolean,
  options?: RegisterOptions,
  placeholder?: string,
  ariaLabel?: string,
  defaultValue?: string
}

export const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold mobile:text-base
focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

const FormInput = ({
  id, type, label, className, inputClassName, disabled, options, placeholder, ariaLabel, defaultValue=''
}: FormInputProps) => {
  const { register, formState } = useFormContext();

  return (
    <div className={classnames('flex-col items-start inline-block', className)}>
      {label && <InputLabel id={id} label={label} />}
      <input
        className={classnames(
          inputDefaultStyles,
          inputClassName,
          { 'bg-gray-100': disabled, 'border-interactions-input-error border-2': formState.errors[id] },
        )}
        type={type}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        {...register(id, options)}
        aria-label={ariaLabel}
        defaultValue={defaultValue}
      />
        
      {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
    </div>
  );
};

export default FormInput;
